'use client'

import useIsMobile from "@/app/utils/useIsMobile";
import Link from "next/link";

const Review = () => {

const isMobile = useIsMobile({maxWidth: 1065})

    return (
        <div id="review-container">
            <div className="review-content gradientBorder">
                <div className="review-box gradientBorder">
                    <div>
                        <img src="./google-logo.svg" alt="google" className="google-logo" />
                    </div>
                    <div className="review-details">
                        <div className="google-rating"><span>Google Rating</span></div>
                        <div className="rating">
                            <span className="rating-value gradientText">4.9</span>
                                <img src="./star.svg" alt="star" width={isMobile ? 150 : 250} />
                        </div>
                        <Link target="_blank" rel="noopener noreferrer" className="reviews-link" href="https://www.google.com/search?q=Stage+Properties+Brokers+LLC+-+Office+106%2C+Building+3+-+Business+Park+-+Dubai+Hills+-+Dubai+-+United+Arab+Emirates&oq=stage+properties&gs_lcrp=EgZjaHJvbWUqBggCEEUYOzIGCAAQRRg7MgYIARBFGDkyBggCEEUYOzIGCAMQRRhBMgYIBBBFGEEyBggFEEUYQTIGCAYQRRg8MgYIBxBFGDzSAQgzNzUxajBqNKgCALACAQ&sourceid=chrome&ie=UTF-8#lrd=0x3e5f69716c4db3e1:0xe69e317a545a19d2,1,,,,">
                        See all our reviews</Link>
                        <Link target="_blank" href='https://www.google.com/search?q=Stage+Properties+Brokers+LLC+-+Office+106%2C+Building+3+-+Business+Park+-+Dubai+Hills+-+Dubai+-+United+Arab+Emirates&oq=stage+properties&gs_lcrp=EgZjaHJvbWUqBggCEEUYOzIGCAAQRRg7MgYIARBFGDkyBggCEEUYOzIGCAMQRRhBMgYIBBBFGEEyBggFEEUYQTIGCAYQRRg8MgYIBxBFGDzSAQgzNzUxajBqNKgCALACAQ&sourceid=chrome&ie=UTF-8#lrd=0x3e5f69716c4db3e1:0xe69e317a545a19d2,1,,,,'>
                            <button className="globalBtn">
                            Leave us a review
                            </button>
                        </Link>

                    </div>
                </div>
                <div className="agency-message">
                    <h2 className="title gradientText mainHeading">
                    A Reputation Built on Customer Satisfaction
                    </h2>
                </div>    
            </div>
        </div>
    )
}

export default Review;