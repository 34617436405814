"use client";
import React from "react";
import dynamic from "next/dynamic";
import { useOnInteraction } from "@/app/components/useOnInteraction/useOnInteraction";
import LoadingUI from "@/app/components/LoadingUI/LoadingUI";


const OurStory = dynamic(() => import("../OurStory/OurStory"));
const OurServices = dynamic(() => import("../OurServices/OurServices"));
const BlogSection = dynamic(() => import("../BlogsSection/BlogSection"));
import FaqSection from "../../components/Faq/FaqSection"

const DynamicFeaturedLuxuries = dynamic(() =>
  import("../../homepageComponents/FeaturedLuxuries/FeaturedLuxuries")
);
const DynamicMeetOurTeam = dynamic(() =>
  import("../../homepageComponents/MeetOurTeam/MeetOurTeam")
);
const DynamicCommunitySection = dynamic(() =>
  import("../../homepageComponents/CommunitySection/CommunitySection")
);
const DynamicMarketOverviewSection = dynamic(() =>
  import("../../homepageComponents/MarketOverviewSection/MarketOverviewSection")
);
const PropertiesListingSection = dynamic(() =>
  import("../../homepageComponents/PropertiesListing/PropertiesListingSection")
);

const Review = dynamic(() => import('../../components/Review/review'))

const HomePageLazyLoad = () => {
  const isUserInteracted = useOnInteraction();
  // if (!isUserInteracted) {
  //   return<LoadingUI/>
  // }
  return (
    <>
      <DynamicFeaturedLuxuries />

      {isUserInteracted &&
      <>
      <Review />
      <OurStory />
      <PropertiesListingSection type={'SECONDARY-SALE'} title={"PROPERTIES FOR SALE"}/>
      <PropertiesListingSection type={'SECONDARY-RENT'} title={"PROPERTIES FOR RENT"}/>
      <OurServices />
      <DynamicMeetOurTeam />
      <DynamicCommunitySection />
      <DynamicMarketOverviewSection />
      </>
      }
      <div style={{
          border: '1px solid transparent',
          borderImage: 'radial-gradient(circle, rgba(255, 236, 224, 1) 0%, rgba(240, 202, 178, 1) 35%, rgba(172, 131, 109, 1) 100%)',
          borderImageSlice: 1,
          borderWidth: '6px',
          borderLeft: 'none',
          borderRight: 'none'
      }}>
        <FaqSection />
      </div>
      <BlogSection />
    </>
  );
};

export default HomePageLazyLoad;
