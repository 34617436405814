"use client"
import { fetchFAQsByPage } from "./service";
import Faq from "./component/Faq";
import { useEffect, useState } from "react";
import { usePathname } from 'next/navigation';

const FaqSection = () => {
    const [data, setData] = useState(null);
    const pathname = usePathname();
    
    const PATHS = {
        '/': 'home',
        '/offplan': 'offplan'
    };

    const path = PATHS[pathname];

    useEffect(() => {
        if (!path) return;
        const fetchFAQs = async () => {
            const faqs = await fetchFAQsByPage({ path });
            setData(faqs);
        };

        fetchFAQs();
    }, [path]);
    
    if (!path || data === null || !data?.length) return null;

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": data.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answerNoFormatting
            }
        }))
    };

    return (
        <>
            <script type="application/ld+json">
                {JSON.stringify(structuredData)}
            </script>
            {
                data && (
                    <div style={{ marginTop: '5rem' }}>
                        <Faq data={data} />
                    </div>
                )
            }
        </>
    )
};

export default FaqSection;